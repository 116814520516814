import { combine } from 'effector-root'

import { dependencyTreeModel } from './core/dependencyTreeModel'
import { treeModel } from './EditorTreeWrap/model'

export const tree$ = combine(
  dependencyTreeModel.flatTree$,
  treeModel.flatTree$,
  (depTree, treeModel) => [...depTree, ...treeModel],
)
