// eslint-disable-next-line import/no-cycle
import { CheckupRepoNode } from './CheckupRepoNode'
// eslint-disable-next-line import/no-cycle
import { CheckupRepoFolderNode } from './CheckupRepoFolderNode'
// eslint-disable-next-line import/no-cycle
import { CheckupNode } from './CheckupNode'
// eslint-disable-next-line import/no-cycle
import { CheckupRuleNode } from './CheckupRuleNode'

export type Node =
  | CheckupRepoNode
  | CheckupRepoFolderNode
  | CheckupNode
  | CheckupRuleNode

export type NodeType = Node['type']

export namespace Node {
  export type Ref =
    | CheckupRepoNode.Ref
    | CheckupRepoFolderNode.Ref
    | CheckupNode.Ref
    | CheckupRuleNode.Ref

  export type Map = Readonly<Record<string, undefined | Node>>

  export function getKey(ref: Ref): string {
    return map[ref.type].getKey(ref as any)
  }

  export function getParentKey(node: Node): null | string {
    const ns = map[node.type]
    const fn = 'getParentKey' in ns ? ns.getParentKey : null
    return fn && fn(node as any)
  }

  export const getByRef = <R extends Ref>(
    nodes: Map,
    ref: Ref,
  ): null | TypeMap[R['type']] => {
    const ns = map[ref.type] as any
    return ns.getByKey(nodes, getKey(ref))
  }
}

const map = {
  CheckupRepoNode,
  CheckupRepoFolderNode,
  CheckupNode,
  CheckupRuleNode,
}

interface TypeMap {
  readonly CheckupRepoNode: CheckupRepoNode
  readonly CheckupRepoFolderNode: CheckupRepoFolderNode
  readonly CheckupNode: CheckupNode
  readonly CheckupRuleNode: CheckupRuleNode
}
