import React from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import { useHistory } from 'react-router-dom'
import { combine, guard, merge, sample } from 'effector'

import { useStore, useStoreMap } from 'effector-react'
import { prop } from 'ramda'

import { useKeycloak } from '@react-keycloak/web'

// import { Layers } from '@material-ui/icons'

import {
  FolderRoot,
  WithSumIcon,
  Header,
  HeaderLogoWrap,
  Logo,
  Resizable,
  ResizablePanel,
  IconButton,
  PlusCircle,
  SeparatorList,
  Tooltip,
  FolderWithPlus,
  ResizableColumnStateItem,
  Cover,
  Substrate,
  FolderWithoutSubstrate,
  WithCursorIcon,
  WarningFilled,
} from '@gmini/ui-kit'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'

import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import {
  DragLayer,
  ModelSelect,
  createSearchModel,
  openExplorer,
  SelectDependencies,
  createSelectDependenciesParams,
  createViewerDisplayModel,
  selectedModels as selectedModelsService,
} from '@gmini/common/lib/classifier-editor'

import { useInclusionFilter } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { createClassifiersTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/ClassifiersTree'

import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import { DependencyTreeWrap } from '@gmini/common/lib/classifier-editor/DependencyTree/DependencyTreeWrap'

import { setCurrentGroup } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu'

import { useReadonlyMode, getNode, getModulesLinkFromEnv } from '@gmini/common'
import * as forgeViewer from '@gmini/common/lib/forge-viewer'

import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'

import {
  VersionButton,
  VersionHistory,
} from '@gmini/common/lib/components/VersionSwitch'

import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'

import { goals } from '@gmini/common/lib/metrika'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/components/VersionSwitch/common'

import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'

import {
  PageContentContainer,
  useNavbar,
  ProjectBadge,
} from '@gmini/common/lib/components'

import { createBimFileSubscriptions } from '@gmini/common/lib/classifier-editor/bimFileSubscriptions'

import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common/utils'

import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import * as smApi from '@gmini/sm-api-sdk'

import { createUserClassifierRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/createTreeModel'

import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'

import { buildCheckupStatusKey, CheckupEntity } from '../../checkups-service'

import * as api from '../../api'

import { checkupsService } from '../../services/checkupsService'
import { fetchForgeToken } from '../../services/forgeService'

import { classifierService } from '../../services/classifierService'

import { notificationService } from '../../services/notificationService'

import { CurrentCheckup } from '../CurrentCheckup'
import { GroupSettings } from '../GroupSettings'

import { currentUserClassifier$, projectUrn$ } from '../CurrentUserClassifier'
import { useSelectedGroupNode } from '../CurrentRule'
import { conditionChanged$ } from '../GroupSettings/conditions/complexConditions'
import { logEventEditor } from '../../config/amplitude'

import { envLinks, hideRevitExtensionLink } from '../../config'

import { RunCheckup } from './RunCheckup/RunCheckup'

import './core/checkup.init'
import './core/fetchConditions.init'
import './core/viewerTheme/init'

import { RvtConfigBtn } from './RvtConfigBtn'
import { EditorTreeWrap } from './EditorTreeWrap'

import { dependencyCheckedModel, editorCheckedModel } from './core/checkedModel'
import { dependencyTreeModel } from './core/dependencyTreeModel'
import {
  fetchAllInclusion,
  filteredFlatTree$,
  inclusionStore$,
  loadOnceNodeInclusion,
} from './core/inclusionModel'

import { dependencyExpandModel } from './core/dependencyExpandModel'
import {
  EditorPanelHeader,
  EditorPanelHeaderIcon,
  EditorPanelHeaderTitle,
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
} from './CheckupEditorPage.styled'
import {
  colorViewerElements,
  defaultViewerThemeColor,
} from './core/viewerTheme'

import { sourceClassifiersLoaded$ } from './core/fetchSourceCls'
import { dependencyRootNodes$ } from './core/dependencyRootNodes'
import {
  expandModel,
  failedNodesFilterIsOn$,
  setFailedNodesFilter,
  treeModel,
} from './EditorTreeWrap/model'
import { searchSourceModel } from './core/searchSourceModel'
import { tree$ } from './search-tree'
import { currentProject$ } from './core/project'
import { viewerDerivatives$ } from './core/fetchViewerModelDerivatives'

const DOWNLOAD_LINK_RVT_PLUGIN =
  'https://drive.google.com/file/d/11TTjPo-qp2CD8BO0gVDwFQ8fLoDoCChE/view?usp=sharing'

const {
  addedDependencyIds$,
  currentModelsByClassifierMap$,
} = createSelectDependenciesParams({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
})

const { resetBimFileIds } = createBimFileSubscriptions({
  subscribe: notificationService.subscriptions.subscribeBimFile,
  unsubscribe: notificationService.subscriptions.unsubscribeBimFile,
})

const inclusionStatusWithTranscript = {
  PARENT_INCLUDED: `Родительский элемент добавлен в правило`,
  SELF_INCLUDED: `Элемент добавлен в правило`,
  CHILDREN_INCLUDED: `Один из дочерних элементов добавлен в правило`,
}

enum TreeSections {
  viewer,
  dependencies,
  tree,
  formula,
}

sample({
  source: checkupsService.checkup.currentCheckup$,
  clock: merge([
    api.Checkup.renameVersion.doneData,
    api.Checkup.removeVersionName.doneData,
  ]),
  fn: (checkup, versionData) => ({ checkup, versionData }),
}).watch(({ checkup, versionData }) => {
  if (checkup?.version === versionData.version) {
    api.Checkup.fetch.defaultContext({
      checkupId: checkup.id,
      checkupVersion: checkup.version,
    })
  }
})

const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: api.Checkup.fetchVersionDates.doneData,
  fetchVersions: api.Checkup.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: api.Checkup.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: api.Checkup.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    api.Checkup.renameVersion.doneData,
    api.Checkup.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
})

export const expandVersionModel = createExpandModel()

const versionHistoryPending$ = combine(
  [
    api.Checkup.fetchNamedVersions.defaultContext.pending$,
    api.Checkup.fetchVersionDates.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const { subscriptions, message } = notificationService
const notification = message.filter({ fn: smApi.NotificationEvent.is })

const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentUserClassifier$,
  notification,
})

const userClassifierTree$ = createUserClassifierRepoTreeModel(
  userClassifierRepoService,
  projectUrn$,
)

const { ClassifiersTree } = createClassifiersTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  tree$: userClassifierTree$,
  currentUserClassifier$,
})

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentUserClassifier$,
  bimFile$,
})

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
    validateModelTypes,
  }),
)

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  tree$,
  currentEntity$: currentUserClassifier$,
})

createViewerDisplayModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
      validateModelTypes,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels = modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

export const { StatusPanel: EditorTreeStatusPanel } = createStatusPanel({
  checkedModel: editorCheckedModel,
  flatTree$: treeModel.flatTree$,
  searchNodeInfo$: searchModel.searchNode$,
})

const searchedBimNode$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNode$,
  fn: (nodes, searchedData) => {
    const node = searchedData?.node
    if (!node) {
      return null
    }
    const element = getBimNode(node, nodes)

    return element || null
  },
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
const modelUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })

guard({
  clock: modelUpdated,
  source: currentUserClassifier$.map(cls =>
    cls ? { classifierId: cls.id, classifierVersion: cls.version } : null,
  ),
  filter: Boolean,
  target: smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit,
})

export const CheckupEditorPage = React.memo(
  ({
    currentCheckup,
    isCurrentVersion,
  }: {
    isCurrentVersion: boolean
    currentCheckup: CheckupEntity
  }) => {
    const rules = useStore(checkupsService.checkupRule.rule$)
    const selectedGroup = useSelectedGroupNode()
    const userInfo = useStore(userInfo$)
    const conditionChanged = useStore(conditionChanged$)
    const history = useHistory()
    const versionHistoryPending = useStore(versionHistoryPending$)
    const currentUserClassifier = useStore(currentUserClassifier$)
    const inCreateNode = useStore(treeModel.inCreateNode$)
    const selectedModels = useStore(selectedModels$)
    const renameCheckupPending = useStore(
      api.Checkup.rename.defaultContext.pending$,
    )
    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )
    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})

    const currentProject = useStore(currentProject$)
    const viewerDerivatives = useStore(viewerDerivatives$)

    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )

    React.useEffect(
      () => () => modelStoreService.resetDependenciesWithModels(),
      [],
    )

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNode.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const { keycloak } = useKeycloak()

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)

    const checkupKey = currentCheckup
      ? buildCheckupStatusKey({
          checkupId: currentCheckup.id,
          checkupVersion: currentCheckup.version,
        })
      : ''

    const id = currentCheckup?.id
    const classifierId = currentCheckup?.classifierId

    React.useEffect(() => {
      if (id && classifierId) {
        subscriptions.subscribeCheckup({ checkupId: id, classifierId })
      }

      return () => {
        if (id && classifierId) {
          subscriptions.unsubscribeCheckup({
            checkupId: id,
            classifierId,
          })
        }
      }
    }, [id, classifierId])

    const currentCheckupStatus = useStoreMap({
      store: checkupsService.checkup.checkupStatus$,
      keys: [checkupKey],
      fn: (statusMap, [key]) => statusMap?.[key] || null,
    })

    React.useEffect(
      () => () => {
        checkupsService.checkup.resetCurrent()
        setCurrentGroup(null)
        classifierService.reset()
        resetBimFileIds()
        // resetDynamicGroupMode()
      },
      [],
    )

    React.useEffect(() => {
      if (currentCheckup && currentCheckupStatus === 'Finished') {
        // TODO bulk api method
        currentCheckup.rules.forEach(ruleId => {
          const rule = rules[ruleId]!
          api.FailedNode.get.defaultContext.submit({
            checkupId: currentCheckup.id,
            checkupVersion: currentCheckup.version,
            items: [
              {
                itemId: rule.groupId,
                itemType: 'UserClassifierGroup',
                limit: 500,
              },
            ],
            ruleId,
          })
        })
      }
    }, [currentCheckup, currentCheckupStatus, rules])

    React.useEffect(() => {
      if (currentCheckupStatus !== 'Finished') {
        checkupsService.failedNode.resetCurrent()
      }
    }, [currentCheckupStatus])

    const {
      InclusionFilterButton,
      InclusionFilterContent,
    } = useInclusionFilter()

    React.useEffect(() => {
      filter.setStorageKey(currentCheckup ? `${currentCheckup.id}` : null)

      return filter.reset
    }, [currentCheckup])

    const createRootGroup = React.useCallback(() => {
      treeModel.setInCreateRootNode()
    }, [])

    const { readonlyMode } = useReadonlyMode()
    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const {
      NamedVersionsSwitch,
      namedVersions,
      closeNamedVersions,
    } = useNamedVersions({
      getNamedVersions: async () =>
        !!(await api.Checkup.fetchNamedVersions.defaultContext({
          checkupId: currentCheckup.id,
        })),
      onDisable: () => {
        expandVersionModel.resetExpanded()
        api.Checkup.fetchVersionDates.defaultContext({
          checkupId: currentCheckup.id,
        })
      },
      onActive: () =>
        versionDates && expandVersionModel.expandAll(versionDates.versionDates),
      disabled: versionHistoryPending,
    })

    const onOpenVersionHistory = React.useCallback(async () => {
      await api.Checkup.fetchVersionDates.defaultContext({
        checkupId: currentCheckup.id,
      })

      setOpenVersionHistory(true)
    }, [currentCheckup])

    const {
      ChangeVersionNameDialog,
      setChangeNameDialog,
      changeNameDialog,
    } = useVersionName<smApi.VersionData>({
      onSubmitChangeName: async ({ name, version }) =>
        !!(await api.Checkup.renameVersion.defaultContext({
          checkupId: currentCheckup.id,
          checkupVersion: version,
          versionName: name,
        })),
    })

    const { ContextMenu, setCtxMenu } = useContextMenu<smApi.VersionData>([
      {
        title: 'Перейти к версии',
        onClick: ({ version }) => {
          window.open(
            `${window.location.origin}/checkup/${currentCheckup.id}/version/${version}`,
            '_blank',
          )
        },
        show: item => item.version !== currentCheckup.version,
      },
      {
        title: 'Переименовать',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !!item.name,
      },
      {
        title: 'Присвоить имя',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !item.name,
      },
      {
        title: 'Удалить название',
        onClick: item => {
          api.Checkup.removeVersionName
            .defaultContext({
              checkupId: currentCheckup.id,
              checkupVersion: item.version,
            })
            .then(version => {
              if (namedVersions) {
                removeVersion({
                  versionDate: fromDateTimeToDate(version.createdDate),
                  version,
                })
              }
            })
        },
        show: item => !!item.name,
      },
    ])

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)

    const resetState = React.useCallback(() => {
      expandModel.resetExpanded()
      editorCheckedModel.resetChecked()
      dependencyCheckedModel.resetChecked()
      classifierService.reset()
    }, [])

    const fetchDependencyModels = React.useCallback(
      (params: { id: number; version: number }) => {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          params,
        )
      },
      [],
    )

    const [panelsDebouncedState, setPanelsDebouncedState] = React.useState<
      ResizableColumnStateItem[]
    >([])

    const onColumnsWidthsChanged = React.useCallback(
      (state: ResizableColumnStateItem[]) => {
        setPanelsDebouncedState(state)
      },
      [],
    )

    const widthFormulaResizableCol =
      panelsDebouncedState[TreeSections.formula]?.widthPx || 0
    const widthTreeResizableCol =
      panelsDebouncedState[TreeSections.tree]?.widthPx || 0
    const widthColMoreMin = widthFormulaResizableCol > 310

    const onRenameEntity = (value: string) => {
      api.Checkup.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentCheckup.parentFolderId,
        id: currentCheckup.id,
        version: currentCheckup.version,
      })
    }

    const loadModelByUrl = !!(currentProject?.sourceType === 'GStation')

    const fetchToken = React.useCallback(async (): Promise<smApi.ForgeToken> => {
      if (loadModelByUrl) {
        return {
          accessToken: keycloak.token!,
          expiresAt: keycloak.tokenParsed!.exp!,
        }
      }

      const token = await fetchForgeToken()

      return token
    }, [keycloak, loadModelByUrl])

    const getViewerModelUrl = React.useCallback(
      (idFromUrn: string) => {
        let url = ''
        viewerDerivatives?.files.forEach(({ derivatives, urn }) => {
          // eslint-disable-next-line prefer-named-capture-group
          const match = urn.match(/urn:gstation\.dm\.file:(\d+)/u)

          if (match?.[1] === idFromUrn) {
            url = derivatives.find(d => d.type === '3D')?.view || ''
          }
        })

        if (!url) {
          throw new Error(`Url not found for model with urn: ${idFromUrn}`)
        }
        return url
      },
      [viewerDerivatives?.files],
    )

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(envLinks, currentProject?.urn),
    })

    const projectBadge = (
      <ProjectBadge
        projectName={currentProject?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    const failedNodesFilterIsOn = useStore(failedNodesFilterIsOn$)

    if (!currentProject) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box height='100%' display='flex' flexDirection='column'>
          <CurrentCheckup />
          <Header
            onLogout={() => keycloak.logout()}
            title={currentCheckup?.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentCheckup.version}
                  versionCreated={currentCheckup.versionCreatedDate}
                  versionName={currentCheckup.versionName}
                  title={currentCheckup?.name}
                  pending={versionHistoryPending}
                  onRename={onRenameEntity}
                  renamePending={renameCheckupPending}
                />
                <Box ml={1} flexGrow={1}>
                  <Tags />
                </Box>
              </>
            }
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProject ? `/?projectUrn=${currentProject?.urn}` : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Проверки</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            rightButton={
              hideRevitExtensionLink === 'true' ? undefined : (
                <>
                  <RvtConfigBtn
                    onClickDownload={() => {
                      window.open(
                        DOWNLOAD_LINK_RVT_PLUGIN,
                        '_blank',
                        'noopener,noreferrer',
                      )
                      goals.downloadCheckupRvtConfig()
                    }}
                    onClickHelp={() => {
                      goals.showCheckupRvtConfigHelp()
                    }}
                  />
                </>
              )
            }
            onProfileClick={() => goals.showUserMenu()}
          />

          <PageContentContainer data-test-id='CheckupsEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              onWidthChangedDelay={50}
              storageKey='CheckupEditorPanels'
              onWidthsChanged={onColumnsWidthsChanged}
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentUserClassifier$}
                      fetchDependencyModels={fetchDependencyModels}
                      searchedBimNode$={searchedBimNode$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                          validateModelTypes,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                  logEventEditor('EXPAND_VIEWER', { entity: 'Checkup' })
                }}
                onClosed={() => {
                  goals.foldViewer()
                  logEventEditor('FOLD_VIEWER', { entity: 'Checkup' })
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNode={searchModel.resetSearchNode}
                  colorViewerElements={colorViewerElements}
                  resetSelectedModels={selectedModelsService.reset}
                  defaultThemeColor={defaultViewerThemeColor}
                  selectViewerElements={selectViewerRefs}
                  loadModelByUrl={loadModelByUrl}
                  getModelUrl={getViewerModelUrl}
                  fetchToken={fetchToken}
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <SeparatorList>
                    <Tooltip placement='top' title='Добавить модель(и)'>
                      <IconButton
                        onClick={openExplorer}
                        data-test-id='openDependencyList'
                        data-test-ui-type='CreateButton'
                        disabled={readonlyMode.enabled}
                      >
                        <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>

                    {InclusionFilterButton}
                  </SeparatorList>
                }
                headerSecondary={<ModelManageMenu />}
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={InclusionFilterContent}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <TreeLoader
                    loading={fetchFlatListDependenciesPending}
                    text='Идет загрузка...'
                  />
                  <Cover
                    open={
                      currentUserClassifier?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />
                  <DependencyTreeWrap
                    currentUserClassifier$={currentUserClassifier$}
                    dynamicGroupsConditions={{}}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    fetchAllInclusion={fetchAllInclusion}
                    filteredFlatTree$={filteredFlatTree$}
                    inclusionStore$={inclusionStore$}
                    loadOnceNodeInclusion={loadOnceNodeInclusion}
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    inclusionStatusWithTranscript={
                      inclusionStatusWithTranscript
                    }
                    sourceClassifiersLoaded$={sourceClassifiersLoaded$}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                  />

                  <SelectDependencies
                    ClassifiersTree={ClassifiersTree}
                    ModelsTree={ModelsTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Правила'
                header={
                  <Tooltip placement='top' title='Добавить папку в правила'>
                    <IconButton
                      onClick={() => createRootGroup()}
                      data-test-id='addRootGroup'
                      data-test-ui-type='CreateButton'
                      disabled={readonlyMode.enabled}
                    >
                      <FolderWithPlus color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                headerSecondary={
                  currentCheckupStatus === 'Finished' && (
                    <Tooltip
                      placement='top'
                      title='Только не прошедшие проверку'
                    >
                      <IconButton
                        color={failedNodesFilterIsOn ? 'warning' : 'default'}
                        selected={failedNodesFilterIsOn}
                        onClick={() =>
                          setFailedNodesFilter(!failedNodesFilterIsOn)
                        }
                      >
                        <WarningFilled />
                      </IconButton>
                    </Tooltip>
                  )
                }
                onOpened={() => {
                  goals.expandClassifier()
                }}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={
                  <EditorTreeStatusPanel
                    content={
                      <RunCheckup
                        widthTreeResizableCol={widthTreeResizableCol}
                        delayedRun={conditionChanged}
                      />
                    }
                  />
                }
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                {currentCheckup && (
                  <>
                    <TreeLoader
                      loading={fetchFlatListItemsPending}
                      text='Идет загрузка...'
                    />
                    <Cover
                      open={
                        currentUserClassifier?.children.length === 0 &&
                        !fetchFlatListItemsPending &&
                        !inCreateNode
                      }
                      icon={
                        <Substrate>
                          <WithCursorIcon>
                            <FolderWithPlus
                              color='#DADADA'
                              width={45}
                              height={45}
                            />
                          </WithCursorIcon>
                        </Substrate>
                      }
                      title={
                        <>
                          Создайте желаемую структуру <br /> папок и перенесите
                          в них необходимые <br />
                          элементы из зависимостей
                        </>
                      }
                    />
                    <EditorTreeWrap
                      currentCheckup={currentCheckup}
                      currentCheckupStatus={currentCheckupStatus}
                      searchModel={searchModel}
                      selectViewerRefs={setSelectViewerRefs}
                      dependenciesWithModels$={
                        modelStoreService.dependenciesWithModels$
                      }
                    />
                  </>
                )}
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Редактор формул'
                data-test-id='FormulaSection'
                onOpened={() => {
                  goals.expandGroupSettings()
                }}
                onClosed={() => {
                  goals.foldGroupSettings()
                }}
                noHeaderSeparator
                bodyStyles={{ borderRight: '' }}
                header={
                  //TODO - Сделать переиспользуемый компонент с кодом ниже
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      <WithSumIcon icon={<FolderRoot />} />
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle data-test-ui-type='Text'>
                      {(widthColMoreMin || !selectedGroup?.name) &&
                        'Редактор формул'}
                      {widthColMoreMin && selectedGroup?.name && `: `}

                      {selectedGroup?.name}
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
              >
                <Cover
                  open={
                    !selectedGroup &&
                    currentUserClassifier?.children.length !== 0
                  }
                  icon={
                    <Substrate p='19px 16px 20px 16px'>
                      <FolderWithoutSubstrate width={40} height={33} />
                    </Substrate>
                  }
                  title={<>Ни одна папка в третьей колонке не выбрана</>}
                  pb='46px'
                />
                <GroupSettings
                  widthFormulaResizableCol={widthFormulaResizableCol}
                />
              </ResizablePanel>
            </Resizable>
          </PageContentContainer>
          <VersionHistory
            open={openVersionHistory}
            onClose={onCloseVersionHistory}
            onRedirectToLast={() => {
              resetState()
              history.push(`/checkup/${currentCheckup.id}`)
            }}
            beforeList={NamedVersionsSwitch}
            isCurrentVersion={isCurrentVersion}
            versionList={
              <VersionList
                onMenuClick={setCtxMenu}
                currentVersion={currentCheckup.version}
                dates={versionDates}
                versions={versions}
                fetchVersions={async versionDate => {
                  await api.Checkup.fetchVersionByDate.defaultContext({
                    checkupId: currentCheckup.id,
                    versionDate,
                  })
                }}
                contextMenu={!changeNameDialog && ContextMenu()}
                openVersionHistory={openVersionHistory}
                expandModel={expandVersionModel}
                anotherListOpened={namedVersions}
              />
            }
          />
          {ChangeVersionNameDialog()}
        </Box>
      </>
    )
  },
)

CheckupEditorPage.displayName = 'CheckupEditorPage'
