import { userInfo$ } from '@gmini/common/lib/auth/auth'
import amplitude from 'amplitude-js'

import { amplitudeApiKey, amplitudeDisabled } from './config'

export function initAmplitude() {
  if (!amplitudeDisabled) {
    amplitude.getInstance().init(amplitudeApiKey)
  }
}

export function amplitudeClient() {
  return amplitude.getInstance()
}

export function logEventEditor(
  event: string,
  properties: {
    entity: 'Checkup'
  },
  additionalProperties: Record<string, any> = {},
) {
  if (amplitudeDisabled) {
    return
  }

  return amplitudeClient().logEvent(event, {
    ...properties,
    ...additionalProperties,
    domain: window.location.host,
    url: window.location.href,
  })
}

// TODO: перенести в компонент в useEffect после интеграции @gmini/auth
userInfo$.watch(info => {
  if (info) {
    amplitudeClient().setUserId(info.id)
  }
})
