import * as React from 'react'
import { useStore } from 'effector-react'

import { SimpleConditions } from '@gmini/common/lib/components'

import { useReadonlyMode } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import { checkupsService } from '../../../services/checkupsService'

import { useCurrentRule } from '../../CurrentRule/useCurrentRule'

import { useRulePropertyList } from '../properties/useRulePropertyList'
import {
  selectedProperties$,
  selectProperties,
  selectPropertiesPending$,
} from '../conditions/simpleConditions'
import { useCheckup } from '../../CurrentCheckup/useCheckup'

const emptyArray: smApi.UserClassifierGroup.Property[] = []

type SimpleConditionsContainerProps = {
  widthFormulaResizableCol: number
}

export const SimpleConditionsContainer = React.memo(
  ({ widthFormulaResizableCol }: SimpleConditionsContainerProps) => {
    const currentCheckup = useStore(checkupsService.checkup.currentCheckup$)
    const currentRule = useCurrentRule()

    const { pending: checkupPending } = useCheckup({
      checkupsService,
    })

    const selectedPropertiesIds = useStore(selectedProperties$)
    const propertiesById = useStore(checkupsService.property.byId$)

    const { propertyList, pending: listPending } = useRulePropertyList()

    const pending = useStore(selectPropertiesPending$)
    const { readonlyMode } = useReadonlyMode()

    return (
      <SimpleConditions
        selectedPropertiesIds={selectedPropertiesIds}
        selectProperties={propertiesIds => {
          if (propertiesIds && currentCheckup && currentRule) {
            selectProperties({
              checkupId: currentCheckup.id,
              checkupVersion: currentCheckup.version,
              properties: propertiesIds,
              ruleId: currentRule.id,
            })
          }
        }}
        availableProperties={propertyList || emptyArray}
        listPending={listPending}
        propertiesById={propertiesById}
        selectPending={pending}
        title='Простые условия'
        disabled={readonlyMode.enabled || checkupPending}
        widthFormulaResizableCol={widthFormulaResizableCol}
      />
    )
  },
)

SimpleConditionsContainer.displayName = 'SimpleConditionsContainer'
