import { PropertyById } from '@gmini/common'

import { FailedNodeReason } from '../../../../api'

const resultTextMap: Record<
  FailedNodeReason['result'],
  (propName: string) => string
> = {
  NO_PROPERTY: name => `Отсутствует "${name}"`,
  EMPTY_PROPERTY: name => `Отсутствует значение у "${name}"`,
  FAILED: name => `Не прошло проверку в формуле "${name}"`,
  INVALID_FORMULA: name =>
    `Ошибка проверки в "${name}", формула содержит ошибку`,
}

export const reasonToText = (
  reason: FailedNodeReason,
  propertyById: PropertyById,
): string => {
  switch (reason.conditionType) {
    case 'SimpleCondition': {
      const property = propertyById[reason.fieldId]
      return resultTextMap[reason.result](property?.name || '!!!app error')
    }
    case 'ComplexCondition': {
      return resultTextMap[reason.result](
        reason.conditionName || '!!!app error',
      )
    }
  }
}
