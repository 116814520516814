import { createInclusionModel } from '@gmini/common/lib/classifier-editor/DependencyTree/Inclusion'
import * as smApi from '@gmini/sm-api-sdk'

import { byInclusion$ } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'
import { notificationService } from '../../../services/notificationService'

import { dependencyExpandModel } from './dependencyExpandModel'
import { dependencyTreeModel } from './dependencyTreeModel'

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

export const {
  fetchAllInclusion,
  loadOnceNodeInclusion,
  inclusionStore$,
  currentInclusionMap$,
  filteredFlatTree$,
} = createInclusionModel({
  flatTree$: dependencyTreeModel.flatTree$,
  expanded$: dependencyExpandModel.expanded$,
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification,
  inclusionFilterStatus$: byInclusion$,
})
