import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'

import { useStore, useStoreMap } from 'effector-react'

import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'
import { goals } from '@gmini/common/lib/metrika'
import { useReadonlyMode } from '@gmini/common'

import {
  IconButton,
  PlusCircle,
  HelpRounded,
  Button,
  Tooltip,
} from '@gmini/ui-kit'

import {
  saveComplexConditions,
  saveComplexConditionsPending$,
  addCondition,
  unsavedConditionsByRule$,
  ComplexCondition,
} from '../conditions/complexConditions'

import * as api from '../../../api'

import { useRulePropertyList } from '../properties/useRulePropertyList'

import { useCheckup } from '../../CurrentCheckup/useCheckup'
import { checkupsService } from '../../../services/checkupsService'

import { currentRule$ } from '../../CurrentRule'

import { ConditionItem } from './ConditionItem'

import { HelpDialog } from './HelpDialog'
import {
  AddButtonWrapper,
  ConditionsContainer,
  HeaderTitle,
  Header,
} from './DifficultConditions.styled'

const emptyArray = [] as any

const emptyConditions: ComplexCondition[] = []

export const DifficultConditions = memo(
  ({
    widthFormulaResizableCol,
    ruleId,
  }: {
    widthFormulaResizableCol: number
    ruleId: number
  }) => {
    const savePending = useStore(saveComplexConditionsPending$)
    const suggestionsInFormula = useStore(
      currentRule$.map(rule => rule?.conditionsElementProperties),
    )

    const conditions = useStoreMap({
      store: unsavedConditionsByRule$,
      keys: [ruleId],
      fn: (rules, [ruleId]) =>
        ruleId && rules[ruleId] ? rules[ruleId] : emptyConditions,
    })

    const { pending: checkupPending } = useCheckup({
      checkupsService,
    })

    useEffect(
      () => () => {
        saveComplexConditions({ ruleId })
      },
      [ruleId],
    )

    const { propertyList } = useRulePropertyList()

    const [helpOpen, setHelpOpen] = useState(false)

    const suggestions = useMemo(() => {
      if (!propertyList) {
        return emptyArray
      }

      return propertyList
    }, [propertyList])

    const onAddCondition = (toBeginning?: boolean) => {
      goals.addCheckupFormula()

      addCondition({
        ruleId,
        newCondition: {
          name: `Колонка ${Number(conditions?.length) + 1}`,
          formula: '',
          initialFormula: '',
        },
        toBeginning,
      })
    }

    const onClickAway = () => {
      if (ruleId && conditions) {
        saveComplexConditions({ ruleId })
      }
    }

    const onValidate = useCallback(async (formula: string) => {
      const res = await api.CheckupRule.validateFormula.defaultContext({
        formula,
      })
      return res.isValid
    }, [])

    const onClickHelp = useCallback(() => {
      setHelpOpen(true)
      goals.showEstimationFormulaHelp()
    }, [])

    const { readonlyMode } = useReadonlyMode()

    const pending = savePending || checkupPending

    return (
      <ClickAwayListener onClickAway={onClickAway} touchEvent='onTouchStart'>
        <div>
          <Header>
            <HeaderTitle>Сложные условия</HeaderTitle>

            <Tooltip placement='top' title='Справка по формулам'>
              <IconButton size='small' onClick={onClickHelp} type='circle'>
                <HelpRounded
                  color='rgba(53, 59, 96, 0.25)'
                  width='20px'
                  height='20px'
                />
              </IconButton>
            </Tooltip>

            <HelpDialog open={helpOpen} onClose={() => setHelpOpen(false)} />
            {savePending && <CircularProgress size={15} />}
            <AddButtonWrapper>
              <Tooltip placement='top' title='Добавить вычислимую колонку'>
                <IconButton
                  onClick={() => onAddCondition(true)}
                  disabled={readonlyMode.enabled || pending}
                >
                  <PlusCircle width='24px' height='24px' />
                </IconButton>
              </Tooltip>
            </AddButtonWrapper>
          </Header>
          <ConditionsContainer>
            {conditions?.map(({ name, initialFormula }, idx) => (
              <ConditionItem
                name={name}
                formula={initialFormula}
                allSuggestions={suggestions}
                suggestionsInFormula={suggestionsInFormula}
                // eslint-disable-next-line react/no-array-index-key
                key={`${ruleId}__${idx}`}
                onValidate={onValidate}
                disabled={readonlyMode.enabled || pending}
                widthFormulaResizableCol={widthFormulaResizableCol}
                idx={idx}
                ruleId={ruleId}
              />
            ))}
          </ConditionsContainer>
          {Number(conditions?.length) > 0 && (
            <Button
              disabled={readonlyMode.enabled || pending}
              onClick={() => onAddCondition()}
              color='secondary'
              leftIcon={<PlusCircle width='24px' height='24px' />}
              size='regular'
            >
              Добавить
            </Button>
          )}
        </div>
      </ClickAwayListener>
    )
  },
)

DifficultConditions.displayName = 'DifficultConditions'
