import * as t from 'io-ts'

// eslint-disable-next-line import/no-cycle
import { SimpleReason } from './SimpleReason'

export type ComplexReasonResult = 'FAILED' | 'INVALID_FORMULA'

export interface ComplexReason {
  conditionType: 'ComplexCondition'
  conditionName: string
  result: ComplexReasonResult
}

export namespace ComplexReason {
  export const io: t.Type<ComplexReason> = t.type({
    conditionType: t.literal('ComplexCondition'),
    conditionName: t.string,
    result: t.union([t.literal('FAILED'), t.literal('INVALID_FORMULA')]),
  })

  export const is = (r: SimpleReason | ComplexReason): r is ComplexReason =>
    r.conditionType === 'ComplexCondition'
}
