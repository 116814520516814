import React from 'react'
import { combine } from 'effector'
import { useStore } from 'effector-react'

import { assertNever } from '@gmini/utils'

import { Box } from '@gmini/ui-kit/lib/Box'

import { Tabs, FormulaeEditorLayout } from '@gmini/ui-kit'

import * as smApi from '@gmini/sm-api-sdk'

import './conditions/saveConditions.init'

import { useCurrentRule } from '../CurrentRule/useCurrentRule'

import { useSelectedGroupNode } from '../CurrentRule'

import { Container } from './GroupSettings.styled'

import { SimpleConditionsContainer } from './SimpleConditionsContainer'
import { DifficultConditions } from './DifficultConditions'
// import { GroupingsContainer } from './GroupingsContainer'

export enum TabIndex {
  Conditions,
  Groupings,
}

const editorDisabled$ = combine(
  [
    smApi.UserClassifierGroup.createGroupReference.defaultContext.pending$,
    smApi.UserClassifierGroup.create.defaultContext.pending$,
    smApi.UserClassifierGroup.move.defaultContext.pending$,
    smApi.UserClassifierGroup.rename.defaultContext.pending$,
    smApi.UserClassifierGroup.remove.defaultContext.pending$,
    smApi.BimReference.create.defaultContext.pending$,
    smApi.BimReference.move.defaultContext.pending$,
    smApi.BimReference.remove.defaultContext.pending$,
    smApi.BimReference.createRefs.defaultContext.pending$,
    smApi.BimReference.moveRefs.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

type GroupSettingsProps = {
  widthFormulaResizableCol: number
}

export const GroupSettings = React.memo(
  ({ widthFormulaResizableCol }: GroupSettingsProps) => {
    const editorDisabled = useStore(editorDisabled$)

    const [tabIndex, setTabIndex] = React.useState(TabIndex.Conditions)

    const handleChange = (newTabIndex: number) => {
      setTabIndex(newTabIndex)
    }

    const currentRule = useCurrentRule()

    const selectedGroup = useSelectedGroupNode()

    if (!selectedGroup || !currentRule) {
      return null
    }

    const renderContent = (currGroup: NonNullable<typeof selectedGroup>) => {
      switch (tabIndex) {
        case TabIndex.Conditions:
          return (
            <>
              <div>
                <Box pt='16px' pb='20px'>
                  <SimpleConditionsContainer
                    widthFormulaResizableCol={widthFormulaResizableCol}
                  />
                </Box>
                <Box pb={5}>
                  <DifficultConditions
                    widthFormulaResizableCol={widthFormulaResizableCol}
                    ruleId={currentRule.id}
                  />
                </Box>
              </div>
            </>
          )

        case TabIndex.Groupings:
          return (
            <div>
              <Box pt={3}></Box>
            </div>
          )

        default:
          return assertNever('Unexpected tabIndex', tabIndex)
      }
    }

    return (
      <Container>
        <Tabs
          onChangeTab={handleChange}
          activeTabIndex={tabIndex}
          headerStyles={{ fontSize: '14px', marginRight: '24px' }}
          contentStyles={{ height: '100%', paddingTop: '0' }}
          tabs={[
            {
              title: 'Условия',
              content: (
                <FormulaeEditorLayout disabled={editorDisabled}>
                  {renderContent(selectedGroup)}
                </FormulaeEditorLayout>
              ),
            },
            // {
            //   title: 'Группировки',
            //   content: (
            //     <FormulaeEditorLayout disabled={editorDisabled}>
            //       {renderContent(selectedGroup)}
            //     </FormulaeEditorLayout>
            //   ),
            // },
          ]}
        />
      </Container>
    )
  },
)

GroupSettings.displayName = 'GroupSettings'
