import { Attention } from '@gmini/ui-kit'

import {
  Code,
  Row,
  RowRightSide,
  RowLeftSide,
  AlertContent,
  AlertTitle,
  AlertText,
  Alert,
  AttentionIcon,
} from './HelpDialog.styled'

export const OperationTabContent = () => (
  <>
    <Row>
      <RowLeftSide>
        <Code>И</Code>
      </RowLeftSide>

      <RowRightSide>
        Логическое И, истинно, если правый И левый аргумент истинны
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>ИЛИ</Code>
      </RowLeftSide>
      <RowRightSide>
        Логическое ИЛИ, истинно, если правый ИЛИ левый аргумент истинны
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>НЕ(...)</Code>
      </RowLeftSide>
      <RowRightSide>
        <div>
          Логическое отрицание, истинно, если аргумент ложный и наоборот.
        </div>
        <div>При наличии других операций необходимо обрамлять скобками</div>
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>НЕПУСТО(имя_свойства)</Code>
      </RowLeftSide>
      <RowRightSide>
        Функция, принимает истинное значение, если проверяемое поле есть в
        элементе модели и имеет значение
      </RowRightSide>
    </Row>
    <Row>
      <RowLeftSide>
        <Code>(...)</Code>
      </RowLeftSide>
      <RowRightSide>Скобки для обозначения приоритета</RowRightSide>
    </Row>
    <Alert>
      <AttentionIcon>
        <Attention />
      </AttentionIcon>

      <AlertContent>
        <AlertTitle>Внимание!</AlertTitle>
        <AlertText>
          До выхода следующей версии сложных условий приоритет указывается
          только с помощью скобок. Операции И и ИЛИ временно имеют одинаковый
          приоритет, т.е. без использования скобок будут выполняться
          последовательно.
        </AlertText>
      </AlertContent>
    </Alert>
  </>
)
