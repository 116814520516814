import { FetchService } from '@gmini/utils'

import * as api from '@gmini/sm-api-sdk'

const forge = new FetchService({ baseUrl: '/api/forge-auth' })

export async function fetchForgeToken() {
  const res = await forge
    .get('/v1/credentials')
    .then(api.ForgeToken.fetch.toPromise)

  return res
}
