import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { Id, Version, UserClassifierGroup } from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { createPredicate } from '../createPredicate'

import { SimpleCondition } from './SimpleCondition'
import { ComplexCondition } from './ComplexCondition'
// eslint-disable-next-line import/no-cycle
import { Checkup } from './Checkup'

export interface CheckupRule {
  id: Id
  type: 'CheckupRule'
  simpleConditions: SimpleCondition[]
  complexConditions: ComplexCondition[]
  conditionsElementProperties: UserClassifierGroup.Property[]
  groupId: Id
  parentCheckupId: Id
  parentCheckupVersion: Version
}

export namespace CheckupRule {
  const domain = ApiDomain.domain('CheckupRule')

  export const io: t.Type<CheckupRule> = t.type(
    {
      id: Id,
      groupId: Id,
      type: t.literal('CheckupRule'),
      simpleConditions: t.array(SimpleCondition.io),
      complexConditions: t.array(ComplexCondition),
      conditionsElementProperties: UserClassifierGroup.Property.List.io,
      parentCheckupId: Id,
      parentCheckupVersion: Version,
    },
    'CheckupRule',
  )

  export const is = createPredicate<CheckupRule>('CheckupRule')

  export const updateConditions = ApiCallService.create<
    {
      readonly checkupId: number
      readonly checkupVersion: number
      readonly ruleId: number
      readonly simpleConditions: SimpleCondition[]
      readonly complexConditions: ComplexCondition[]
    },
    CheckupRule
  >({
    name: 'updateConditions',
    parentDomain: domain,
    result: io,
    getKey: ['checkupId', 'checkupVersion', 'ruleId'],
  })

  // export type Status = t.TypeOf<typeof Status>

  // export interface ResultStatus {
  //   id: Id
  //   parentCheckupId: Id
  //   type: 'CheckupRuleStatus'
  //   version: Version
  //   status: Status
  // }

  // export const Status = t.union([
  //   t.literal('NotStarted'),
  //   t.literal('Started'),
  //   t.literal('InProgress'),
  //   t.literal('Finished'),
  //   t.literal('Error'),
  // ])

  // export namespace ResultStatus {
  //   export const io: t.Type<ResultStatus> = t.type({
  //     id: Id,
  //     parentCheckupId: Id,
  //     type: t.literal('CheckupRuleStatus'),
  //     version: Version,
  //     status: Status,
  //   })
  // }

  // export const fetchStatus = ApiCallService.create<
  //   {
  //     checkupId: number
  //     checkupVersion: number
  //   },
  //   ResultStatus
  // >({
  //   name: 'status',
  //   parentDomain: domain,
  //   result: ResultStatus.io,
  //   getKey: ['checkupId', 'checkupVersion'],
  // })

  export const create = ApiCallService.create<
    {
      checkupId: number
      checkupVersion: number
      groupId: number
    },
    CheckupRule
  >({
    name: 'create',
    parentDomain: domain,
    result: CheckupRule.io,
    getKey: ['checkupId', 'checkupVersion', 'groupId'],
  })

  export const remove = ApiCallService.create<
    {
      checkupId: number
      checkupVersion: number
      ruleId: number
    },
    Checkup
  >({
    name: 'remove',
    parentDomain: domain,
    result: t.recursion('Checkup', () => Checkup.io),
    getKey: ['checkupId', 'checkupVersion', 'ruleId'],
  })

  export type ValidateFormulaResponse = {
    isValid: boolean
  }

  export const ValidateFormulaResponse: t.Type<ValidateFormulaResponse> = t.type(
    {
      isValid: t.boolean,
    },
  )

  export const validateFormula = ApiCallService.create<
    { formula: string },
    ValidateFormulaResponse
  >({
    result: ValidateFormulaResponse,
    name: 'validateFormula',
    getKey: ['formula'],
    parentDomain: domain,
  })
}
