import { combine, forward, merge, sample } from 'effector'

import { prop } from 'ramda'

import {
  isApiFlatNode,
  resetChecked,
  selectedModels,
  updateChecked,
  ViewerCheckedMap,
} from '@gmini/common/lib/classifier-editor'

import { createDependencyModelUpdatedEvent } from '@gmini/common/lib/classifier-editor/dependencyModelUpdatedEvent'

import { setExtensionsToLoad } from '@gmini/common/lib/forge-viewer/extensions'

import * as smApi from '@gmini/sm-api-sdk'

import { isNotEmpty } from '@gmini/utils'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import * as api from '../../../api'

import { checkupsService } from '../../../services/checkupsService'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { notificationService } from '../../../services/notificationService'

import { classifierService } from '../../../services/classifierService'

import { dependencyCheckedModel, editorCheckedModel } from './checkedModel'

import { dependencyTreeModel } from './dependencyTreeModel'

checkupsService.checkup.currentCheckup$.updates.watch(checkup => {
  if (checkup) {
    api.Checkup.fetchStatus.defaultContext.submit({
      checkupId: checkup.id,
      checkupVersion: checkup.version,
    })
  }
})

dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    editorCheckedModel.resetChecked()
  }
})

editorCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    dependencyCheckedModel.resetChecked()
  }
})

merge([
  smApi.BimReference.moveRefs.defaultContext.done,
  smApi.UserClassifierGroup.createGroupReference.defaultContext.done,
  smApi.BimReference.createRefs.defaultContext.done,
  smApi.BimReference.remove.defaultContext.done,
  smApi.UserClassifier.removeAll.defaultContext.done,
]).watch(() => {
  dependencyCheckedModel.resetChecked()
  editorCheckedModel.resetChecked()
})

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

const smUpdated = notification
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))

forward({
  from: sample(
    currentUserClassifier$,
    smUpdated.filter({ fn: smApi.BaseClassifier.is }),
    (currentUserClassifier, sourceClassifier) => ({
      currentUserClassifier,
      sourceClassifier,
    }),
  ).filterMap(({ currentUserClassifier, sourceClassifier }) => {
    if (
      currentUserClassifier?.sourceClassifiers.some(
        item =>
          item.type === 'BaseClassifierNode' && item.id === sourceClassifier.id,
      )
    ) {
      return currentUserClassifier
    }
  }),
  to: smApi.getSourceClassifiersVersions.submit,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      {
        tree: dependencyTreeModel.flatTree$,
        checked: dependencyCheckedModel.checked$,
      },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Source',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Source')
    resetShowMode()
  }
})

const modelDependencyUpdatedEvent = createDependencyModelUpdatedEvent({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification,
})

/**
 * Загруженные модели
 */
export const currentLoadedModels$ = combine(
  {
    currentCheckup: checkupsService.checkup.currentCheckup$,
    //TODO выпилить оставшийся selectedModels, продумать поиск модели и её рефа по urn
    selectedModels: selectedModels.models$,
  },
  ({ currentCheckup, selectedModels }) =>
    selectedModels && currentCheckup
      ? selectedModels
          .map(model => ({
            modelId: model.modelId,
            urn: model.viewerId,
            baseClassifierId: model.baseClassifierId,
            checkupId: currentCheckup.id,
            checkupVersion: currentCheckup.version,
          }))
          .filter(isNotEmpty)
      : null,
)

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion, modelFromEvent }) => {
  smApi.getSourceClassifiersVersions.submit({
    id: clsId,
    version: clsVersion,
  })
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion }) => {
  smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext.submit(
    {
      id: clsId,
      version: clsVersion,
    },
  )
})
// Загрузка расширений для вьювера
setExtensionsToLoad(['AutofocusExtension', 'BIMSoft.Extensions.Filtering'])
