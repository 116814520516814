import { MAX_NESTING_LEVEL } from '../config'

export type MessageMapCode =
  | 'NOT_UNIQUE_NAME'
  | 'MAX_NESTING_LEVEL'
  | 'ACCOUNT_DISABLED'
  | 'PASSWORD_NOT_SET'
  | 'CIRCULAR_DEPENDENCIES_NOT_SUPPORTED'
  | 'ERROR_REMOVE_DEPENDENCY_HAS_REFERENCES'
  | 'ERROR_DELETE_CLASSIFIER_HAS_DEPENDENTS'
  | 'CLASSIFIER_VERSION_NOT_LAST_ONE'
  | 'PARENT_OR_CHILD_ALREADY_BEEN_ADDED'
  | 'DEPENDENCIES_ALREADY_HAS_MODEL_DIFFERENT_VERSION'
  | 'DEPENDENCIES_ALREADY_HAS_CLASSIFIER_DIFFERENT_VERSION'
  | 'CHECKUP_NOT_FOUND'
  | 'RULE_NOT_FOUND'
  | 'NAME_REQUIRED'
  | 'ALREADY_BEEN_ADDED'
  | 'DIRECT_GROUP_CANNOT_BE_MODIFIED'
  | 'INDIRECT_GROUP_CANNOT_BE_MODIFIED'
  | 'GROUP_CONTENT_ERROR'
  | 'INDIRECT_MODEL_ELEMENT_REF_CANNOT_BE_MODIFIED'
  | 'FOLDER_CANNOT_BE_MOVED_TO_SUBFOLDER'
  | 'GROUP_CANNOT_BE_ADDED_TO_ITS_SUBGROUP'
  | 'PERMISSION_DENIED'
  | 'NOT_AUTHORIZED'
  | 'BAD_CREDENTIALS'
  | 'CLASSIFIER_FOLDER_NAME_IS_NOT_SPECIFIED'
  | 'ASSEMBLY_EXECUTION_SECOND_TIME'
  | 'GENERATE_ASSEMBLY_EXECUTION_MAX_ELEMENT_COUNT'
  | 'CONDITION_ALREADY_EXIST_IN_THE_GROUP_BRANCH'

export type MessageMap = Record<MessageMapCode, string>

export const messageMap: MessageMap = {
  NOT_UNIQUE_NAME: 'Имя уже используется, пожалуйста, задайте другое',
  MAX_NESTING_LEVEL: `Максимальный уровень вложенности ${MAX_NESTING_LEVEL}`,
  CIRCULAR_DEPENDENCIES_NOT_SUPPORTED:
    'Нельзя создавать циклические зависимости',
  ERROR_REMOVE_DEPENDENCY_HAS_REFERENCES:
    'Невозможно удалить зависимость, сначала удалите разложенные элементы',
  ERROR_DELETE_CLASSIFIER_HAS_DEPENDENTS:
    'Невозможно удалить классификатор, он добавлен в зависимость',
  CLASSIFIER_VERSION_NOT_LAST_ONE:
    'Версия классификатора устарела. Пожалуйста, выберите последнюю версию',
  ALREADY_BEEN_ADDED:
    'Добавление невозможно. Папка уже содержит этот элемент модели',
  PARENT_OR_CHILD_ALREADY_BEEN_ADDED:
    'Добавление невозможно. Папка уже содержит родительский или дочерний элемент модели',
  GROUP_CONTENT_ERROR:
    'Добавление невозможно. Папка не может содержать одновременно папки и элементы модели',
  INDIRECT_MODEL_ELEMENT_REF_CANNOT_BE_MODIFIED:
    'Добавление невозможно. Нельзя разбивать перенесенную структуру',
  DEPENDENCIES_ALREADY_HAS_MODEL_DIFFERENT_VERSION:
    'В зависимостях классификатора уже содержится модель другой версии',
  DEPENDENCIES_ALREADY_HAS_CLASSIFIER_DIFFERENT_VERSION:
    'В зависимостях классификатора уже содержится классификатор другой версии',
  CHECKUP_NOT_FOUND: 'Проверка не найдена',
  RULE_NOT_FOUND: 'Правило не найдено',
  NAME_REQUIRED: 'Имя обязательно',
  DIRECT_GROUP_CANNOT_BE_MODIFIED:
    'Вы не можете добавить элементы в папку перенесенную из пользовательского классификатора',
  INDIRECT_GROUP_CANNOT_BE_MODIFIED:
    'Вы не можете добавить элементы в папку перенесенную из пользовательского классификатора',
  FOLDER_CANNOT_BE_MOVED_TO_SUBFOLDER:
    'Нельзя переносить родителькую папку в дочернюю',
  GROUP_CANNOT_BE_ADDED_TO_ITS_SUBGROUP:
    'Нельзя переносить родителькую папку в дочернюю',
  PERMISSION_DENIED: 'Авторизация невозможна. Обратитесь к вашему менеджеру',
  NOT_AUTHORIZED: 'Необходимо авторизоваться',
  BAD_CREDENTIALS: 'Неправильный логин или пароль',
  ACCOUNT_DISABLED: 'Авторизация невозможна. Обратитесь к вашему менеджеру',
  PASSWORD_NOT_SET:
    'Пожалуйста, смените временный пароль на постоянный и войдите с ним',
  CLASSIFIER_FOLDER_NAME_IS_NOT_SPECIFIED: 'Имя папки не может быть пустым',
  ASSEMBLY_EXECUTION_SECOND_TIME:
    'В данной версии запуск сборки возможен 1 раз',
  GENERATE_ASSEMBLY_EXECUTION_MAX_ELEMENT_COUNT:
    'Суммарное количество элементов в сборке не должно превышать 500 тыс., пожалуйста уменьшите количество элементов',
  CONDITION_ALREADY_EXIST_IN_THE_GROUP_BRANCH:
    'Выбранная группировка уже задана в цепочке папок',
} as const

export const notShownMessages = [
  'MISSING_REFRESH_TOKEN',
  'INVALID_REFRESH_TOKEN',
  'RESOURCE_NOT_FOUND',
]
