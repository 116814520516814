import { createTreeModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/treeModel'
import {
  updateChecked,
  ViewerCheckedMap,
  resetChecked,
} from '@gmini/common/lib/classifier-editor'
import { isApiFlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'
import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'
import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'
import { subscribeSelectNewGroup } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'
import * as smApi from '@gmini/sm-api-sdk'

import { combine } from 'effector'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'
import { editorCheckedModel } from '../core/checkedModel'
import { createFailedNodesFilterModel } from '../core/failedNodesFilterModel'

export const { flatTree$, ...restTreeModel } = createTreeModel({
  currentUserClassifier$,
  nodes$: classifierService.nodes$,
  onCreatedNode: smApi.UserClassifierGroup.create.done,
  dynamicMode$: dynamicGroupMode$,
})

export const {
  filteredFlatTree$,
  filterIsOn$: failedNodesFilterIsOn$,
  setFilter: setFailedNodesFilter,
} = createFailedNodesFilterModel({
  flatTree$,
  nodes$: classifierService.nodes$,
})

export const treeModel = {
  ...restTreeModel,
  flatTree$: filteredFlatTree$,
}

subscribeSelectNewGroup({
  flatTree$: treeModel.flatTree$,
  onCreatedNode: smApi.UserClassifierGroup.create.doneData,
})

export const expandModel = createExpandModel()

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
editorCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      { tree: treeModel.flatTree$, checked: editorCheckedModel.checked$ },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Own',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})
